@import "../../../../../../../../../../style-variables-colors.scss";

.r-VideoFeedView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;

  >.video-conference {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1 1 auto;

    >.remote-access-video-container {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      >.remote-access-video {
        display: flex;
        flex: 1 1 auto;
        height: 125%; // to stretch the video to parent div's size
        width: 100%;

        >video {
          display: flex;
          flex: 1 1 auto;
          object-fit: contain;
          transform: rotate(180deg);
        }
      }

      >.connection-status {
        font-size: 32px;
        font-weight: 500;
        color: #B6BEC2;
      }
    }
  }
}
